import React from "react";
import { Helmet } from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import TestimonialCard from "../components/TestimonialCard";
import * as styles from "./index.module.css";

// images
import checkCircle from "../images/check-circle.png";
import logo from "../images/logo-full.png";
import cardMachine from "../images/poynt-5-card 1.png";
import smartTerminal from "../images/smart-terminal.jpg";
import standardTerminal from "../images/standard-terminal.jpg";
import eCommerceIcon from "../images/e-commerce.png";
import businessLoansIcon from "../images/business-loans.png";
import leasesIcon from "../images/leases.png";
import providers from "../images/providers.png";
import positouchPos from "../images/positouch-pos-cash-register.png";

const IndexPage = ({ location }) => {
  const { language, originalPath } = useI18next();

  return (
    <Layout url={originalPath}>
      <Helmet>
        <title>Home | MyEZSolutions</title>
      </Helmet>

      <section className={styles.hero}>
        <div className={styles.heroLeft}>
          <img src={logo} />
          <h2 className={language === "en_US" ? styles.usCta : null}>
            {language === "en_US"
              ? "Save up to 90%"
              : "Reduce your payment provider fees by up to 50%"}
          </h2>
          {language === "en_US" ? (
            <h5 className={styles.usCtaFinePrint}>
              *When{" "}
              <a href="/en_US/solutions/surcharge-program">surcharge program</a>{" "}
              applied
            </h5>
          ) : null}
          <div>
            {language === "en_US" ? <h3>Get a free terminal!</h3> : null}
            <a className={styles.heroCta} href={"/get-quote"}>
              {language === "en_US" ? "Sign up" : "Get started"}
            </a>
          </div>
        </div>
        <div className={styles.heroRight}>
          <img src={cardMachine} />
          <img src={providers} />
          <div className={styles.heroContactUs}>
            <svg style={{width: 28, height: 28, marginRight: "0.5em"}} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
              />
            </svg>
            <h1>1-877-757-1170</h1>
          </div>
        </div>
      </section>

      <section className={styles.pageSection}>
        <div className={styles.pageSectionTitle}>
          <h2>
            <b>
              We Make it <i>EZ</i>
            </b>
          </h2>
        </div>

        <div className={styles.pageSectionContent}>
          <div className={styles.benefitContainer}>
            <div className={styles.benefitItem}>
              <img src={checkCircle} />
              <p>100% Transparent</p>
            </div>
            <div className={styles.benefitItem}>
              <img src={checkCircle} />
              <p>No Hidden Fees</p>
            </div>
            <div className={styles.benefitItem}>
              <img src={checkCircle} />
              <p>Free No-Commitment Quote</p>
            </div>
            <div className={styles.benefitItem}>
              <img src={checkCircle} />
              <p>24/7 Support</p>
            </div>
            <div className={styles.benefitItem}>
              <img src={checkCircle} />
              <p>Daily Processed Transactions</p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.pageSection}>
        <div className={styles.pageSectionTitle}>
          <h2>Our Solutions</h2>
        </div>
        <div className={styles.cardsContainer}>
          <div className={styles.categoryCard}>
            <h3>Smart Terminals</h3>
            <img src={smartTerminal} />
            <a href="/solutions/smart-terminals">
              <div className={styles.categoryCta}>Learn More</div>
            </a>
          </div>
          <div className={styles.categoryCard}>
            <h3>Standard Terminals</h3>
            <img src={standardTerminal} />
            <a href="/solutions/standard-terminals">
              <div className={styles.categoryCta}>Learn More</div>
            </a>
          </div>
          <div className={styles.categoryCard}>
            <h3>E-Commerce</h3>
            <img src={eCommerceIcon} />
            <a href="/solutions/ecomm-solutions/">
              <div className={styles.categoryCta}>Learn More</div>
            </a>
          </div>
        </div>
      </section>
      <section className={styles.pageSection}>
        <div className={styles.pageSectionTitle}>
          <h2>Our Services</h2>
        </div>
        <div className={styles.cardsContainer}>
          <div className={styles.categoryCard}>
            <h3>POS Systems</h3>
            <img src={positouchPos} />
            <a href="/services/pos">
              <div className={styles.categoryCta}>Learn More</div>
            </a>
          </div>
          <div className={styles.categoryCard}>
            <h3>Business Loans</h3>
            <img src={businessLoansIcon} />
            <a href="/services/business-loans">
              <div className={styles.categoryCta}>Learn More</div>
            </a>
          </div>
          <div className={styles.categoryCard}>
            <h3>Leases</h3>
            <img src={leasesIcon} />
            <a>
              <div className={styles.categoryCta}>Learn More</div>
            </a>
          </div>
        </div>
      </section>
      <section className={styles.pageSection}>
        <div className={styles.pageSectionTitle}>
          <h2>Testimonials</h2>
        </div>

        <div className={styles.pageSectionContent}>
          <TestimonialCard name="Taylor Madew">
            I saw their ad on facebook, and I decided to try them out. Im super
            happy with the results and service so far! Great customer service,
            it was super easy to set everything up. I personally spoke to a
            gentleman named Blake, super helpful and respectful. Thanks for all
            the help guys!
          </TestimonialCard>
          <TestimonialCard name="Samer Itani">
            Working with EZ solutions was such a pleasure. They made getting a
            merchant account easy and stress free. I would like to thank the MY
            EZ team for making the process seamless. Definitely would recommend!
          </TestimonialCard>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
